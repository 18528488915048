<template>
  <div
    class="notification-items__date-container"
  >
    <p class="label-small uppercase text-gray-light user-select-none">
      {{ title }}
    </p>
    <div 
      v-if="showReadAllBtn"
      class="date-container__read-all"
      :class="{
        'date-container__read-all--no-bg': !unread_total,
      }"
      @click="postAllNotificationsViewed"
    >
      <v-progress-circular 
        v-if="loadingPostAll"
        class="mr-8"
        indeterminate
        size="16"
        width="2"
      ></v-progress-circular>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="unread_total" v-bind:svg-inline="''" v-bind:class="'mr-8'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".4" d="M12.54 6.014c-.302 0-.7-.007-1.197-.007-1.21 0-2.206-1.002-2.206-2.224V1.64a.304.304 0 00-.301-.306H5.309c-1.645 0-2.976 1.351-2.976 3.006v7.184c0 1.736 1.394 3.144 3.114 3.144h5.25c1.64 0 2.97-1.342 2.97-2.999V6.314a.302.302 0 00-.302-.305c-.282.002-.62.005-.826.005z" fill="#EF7F1A"/><path opacity=".4" d="M10.723 1.712a.318.318 0 00-.548.222v1.758a1.35 1.35 0 001.345 1.344c.465.006 1.11.007 1.658.006.28-.001.423-.336.229-.54-.704-.73-1.963-2.041-2.684-2.79z" fill="#EF7F1A"/><path fill-rule="evenodd" clip-rule="evenodd" d="M5.983 7.592h2.256a.496.496 0 100-.993H5.983a.496.496 0 000 .993zm0 3.33h3.63a.496.496 0 100-.993h-3.63a.496.496 0 000 .992z" fill="#EF7F1A"/></svg>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:class="'mr-8'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path clip-rule="evenodd" d="M10.89 1.833H5.11c-2.014 0-3.276 1.427-3.276 3.444v5.446c0 2.018 1.256 3.444 3.276 3.444h5.779c2.02 0 3.278-1.426 3.278-3.444V5.277c0-2.018-1.257-3.444-3.277-3.444z" stroke="#90BC34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.627 8l1.582 1.582 3.164-3.164" stroke="#90BC34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
      <p
        v-if="unread_total"
        class="caption"
        :class="(loadingPostAll || !unread_total) ? 'text-gray-light' : 'text-primary-orange'"
      >
        {{ $translate('notifications_read-all-action') }}
      </p>
      <p
        v-else
        class="caption text-succsesfull"
      >
        {{ $translate('notifications_read-all-complete') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import baseApi from "@/helpers/baseApi";
import app from '@/helpers/app';

export default {
  name: 'NotificationsDateContainer',
  props: ['title', 'showReadAllBtn'],
  data(){
    return {
      loadingPostAll: false,
    }
  },
  computed: {
    ...mapGetters('notifications', [
      'unread_total'
    ])
  },
  methods: {

    async postAllNotificationsViewed(){
      if (this.loadingPostAll || !this.unread_total) return;
      this.loadingPostAll = true;
      await baseApi.post('api/notification/all_notifications_viewed/').then(() => {
        this.$store.dispatch('notifications/viewAll');
        this.$store.dispatch('layout/alert', this.$translate('notifications_read-all-complete-alert'));
        app.setHeaderMenuItems({
          notifications_count: this.unread_total,
        })
      }).catch((err) => {
        this.$store.dispatch('layout/alert', err);
      }).finally(() => {
        this.loadingPostAll = false;
      })
    }

  }
}
</script>
<style lang="scss">
.notification-items__date-container{
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  margin: 16px 16px 8px;
  .date-container__read-all{
    display: flex; 
    align-items: center; 
    background: #FFFFFF;
    padding: 4px 12px;
    border-radius: 12px;
    user-select: none;
    &.date-container__read-all--no-bg{
      background: none;
    }
  }
}
</style>