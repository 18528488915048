<template>
  <div class="notifications-push-category">
    <div class="notifications-push-category__info">
      <p class="small-medium">
        <span
          class="notifications-push-category__info__dot"
        ></span>
        {{ title }}
      </p>
      <p 
        v-if="description"
        class="caption"
      >{{ description || '' }}</p>
    </div>
    <div>
      <div 
        class="notifications-push-category__toggler"
        :class="{
          'notifications-push-category__toggler--active': value,
          'notifications-push-category__toggler--disabled': disabled,
        }"
        @click="$emit('onClick')"
      >
        <div class="notifications-push-category__toggler__circle-container">
          <div class="notifications-push-category__toggler__circle"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PushCategorySwitch',
  props: [
    'color', 
    'title', 
    'description',
    'value',
    'disabled',
  ],
  data(){
    return {
      active: true
    }
  }
}
</script>
<style lang="scss">
.notifications-push-category{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;
  padding: 14px 16px;
  background: #FFFFFF;
  border-radius: 12px;
  &__info{
    flex: 1 1 auto;
    margin: 0 8px 0 0;
    &__dot{
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: auto 8px auto auto;
      background: #EF7F1A;
      border-radius: 50px;
    }
    .caption{
      margin-top: 4px;
      color: #667A8B;
    }
  }
  &__toggler{
    background: #EEF0F2;
    border-radius: 15px;
    // height: 20px;
    padding: 2px;
    width: 32px;
    // position: relative;
    &__circle-container{
      width: 100%;
      margin-left: auto;
      margin-right: 0;
      transition: width 0.1s ease-in;
    }
    &__circle{
      background: #FFFFFF;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
      // transition-property: left, right;
      // transition-duration: 3s, 5s;
      
    }
    &--active {
      background: #0086E7;
      .notifications-push-category__toggler__circle-container{
        width: 16px;   
      }
    }
    &--disabled{
      background: #EEF0F2;
    }
    
  }
}
</style>