<template>
  <router-link 
    class="notification-items__item"
    :to="{
      name: 'FormPageByUrl',
      query: {
        form_url: url,
        from: 'NotificationsPage'
      }
    }"
  >
    <div class="item__content">
      <p 
        class="small-medium"
        :style="disabled && 'color: #DDE1E5;'"  
      >
        <span
          v-if="!viewed" 
          class="item__orange-dot"
        ></span>
        {{ title }}
      </p>
      <p 
        v-if="description"
        class="caption"
        :style="disabled && 'color: #DDE1E5;'"  
      >{{ description || '' }}</p>
    </div>
    <div>
      <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".2" d="M1 11l5-5-5-5" stroke="#111114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ChildItemLink',
  props: [
    "category",
    'description',
    'disabled',
    'title',
    'url',
    'viewed',
  ],
}
</script>
<style lang="scss">
.notification-items__item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin: 4px 0;
  padding: 14px 16px;
  background: #FFFFFF;
  &:not(:last-child){
    border-bottom: 1px solid #F3F3F3;
  }
  .item__icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    img{
      max-width: 100%;
    }
  }
  .item__content{
    flex: 1 1 auto;
    margin: 0 16px 0 0;
    .item__orange-dot{
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: auto 8px auto auto;
      background: #EF7F1A;
      border-radius: 50px;
    }
    .caption{
      margin-top: 4px;
      color: #667A8B;
    }
    // p:empty{
    //   height: 1.25em;
    // }
  }
  .item__icon-right{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    img{
      max-width: 100%;
    }
  }
}
</style>