<template>
  <v-snackbar 
    centered
    :elevation="0"
    :timeout="-1"
    :width="64"
    :min-width="0"
    rounded
    :value="value"
    :color="theme_color"
    style="z-index: 15;"
  >
    <v-progress-circular
      indeterminate 
      color="#FFF"
    />
  </v-snackbar>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: ['value'],
  computed: {
    ...mapState('layout', ['theme_color']),
  }
}
</script>

<style>

</style>