<template>
  <main class="layout__body layout__body--notifications">
    <PushSettingsLink />
    <div 
      v-if="items.length"
      class="notification-items"
    >
      <template v-if="maped_by_date">
        <template v-for="(dateItem, date_key) in maped_by_date">
          <DateContainer 
            :key="date_key"
            :showReadAllBtn="date_key === 0"
            :title="dateItem.title"
          />
          <div 
            class="notification-items__items-list"
            :key="date_key"
          >
            <ChildItemLink 
              v-for="(item, key) in dateItem['items']"
              :key="date_key+'_'+key"
              :url="item.url"
              :disabled="item.disabled"
              :viewed="item.viewed"
              :title="item.title"
              :description="item.description"
              :category="item.category"
              @click.native="postViewed(item.id)"
            />
          </div>
        </template>
      </template>
      <template v-else>
        <ChildItemLink 
          v-for="(item, key) in by_menu_item_id(menu_item_id)"
          :key="key"
          :url="item.url"
          :disabled="item.disabled"
          :viewed="item.viewed"
          :title="item.title"
          :description="item.description"
          :category="item.category"
          @click.native="postViewed(item.id)"
        />
      </template>
    </div>  
    <div v-else>
      <p class="h6 mx-16">{{ $translate('notifications_empty') }}</p>
    </div>
  </main>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import baseApi from "@/helpers/baseApi";
import ChildItemLink from '@/components/notifications/ChildItemLink';
import DateContainer from '@/components/notifications/DateContainer';
import PushSettingsLink from '@/components/notifications/PushSettingsLink';
import { backNavigateQueue, setHeaderMenuItems } from '@/helpers/app';

export default {
  name: 'NotificationsPage',
  components: {
    PushSettingsLink,
    ChildItemLink,
    DateContainer,
  },
  props: ['menu_item_id'],
  computed: {
    ...mapState('notifications', [
      'items', 
      'status'
    ]),
    ...mapGetters('notifications', [
      'by_menu_item_id',
      'item_by_id',
      'maped_by_date',
      'unread_total'
    ]),
  },
  methods: {
    async postViewed(id){
      if (this.item_by_id(id)?.viewed) return;
      await baseApi.patch('api/notification/notification/'+id+'/', {
        viewed: true,
      }).then(() => {
        this.$store.dispatch('notifications/setItemViewedById', id);
        setHeaderMenuItems({
          notifications_count: this.unread_total,
        })
      }).catch((err) => {
        this.$store.dispatch('layout/alert', err);
      });
    },
    backHandler(){
      this.$router.replace({
        name: 'MainPage',
      })
    }
  },
  mounted(){
    (async () => {
      if (!this.status){ // if status is undefined
        this.$store.dispatch('notifications/setEnabled')
      }
    })();
    // navigate
    this.$store.dispatch('layout/setHeaderTitle', this.$translate('title_notifications'));
    backNavigateQueue.set(this.backHandler);
    // fetch notifications
    this.$store.dispatch('notifications/get');
  }
}
</script>

<style lang="scss">
.notification-items{
  display: flex;
  flex-direction: column;
  &__items-list{
    overflow: hidden;
    border-radius: 8px;
  }
}
</style>