<template>
  <main class="layout__body">
    <CenteredSpinner 
      :value="requestSent"
    />
    <PushSwitch />
    <p v-if="loading">{{ $translate('notifications-settings_categories-loading') }}</p>
    <template v-else-if="error">
      <p>{{ $translate('notifications-settings_categories-loading-error')}}</p>
      <p>{{ error }}</p>
    </template>
    <template v-else-if="inactiveMenuIDs">
      <PushCategorySwitch 
        v-for="(category, k) in menu_parents"
        :key="k"
        :color="category.header_color"
        :description="category.subtitle"
        :title="category.title"
        :value="!inactiveMenuIDs.includes(category.id)"
        :disabled="requestSent || notifications_status === 'disabled'"
        @onClick="changeNotificationStatus(category.id)"
      />
    </template>
  </main>
</template>

<script>
import baseApi from "@/helpers/baseApi";
import CenteredSpinner from '@/components/reusable/CenteredSpinner'
import PushCategorySwitch from "@/components/notifications/PushCategorySwitch.vue"
import PushSwitch from "@/components/notifications/PushSwitch"
import { mapGetters, mapState } from 'vuex';
import { backNavigateQueue } from '@/helpers/app';

export default {
  name: "NotificationsSettingsPage",
  components: { 
    CenteredSpinner,
    PushCategorySwitch, 
    PushSwitch,
  },
  data(){
    return {
      loading: true,
      error: undefined,
      requestSent: false,
      inactiveMenuIDs: undefined,
      userId: undefined,
    }
  },

  computed: {
    ...mapGetters('mainPage', ['menu_parents']),
    ...mapState('notifications', {
      notifications_status: 'status'
    }),
  },

  methods: {
    
    async getNotificationsStatus(){
      this.loading = true;
      try {
        let res = await baseApi.get('api/notification/config/');
        this.inactiveMenuIDs = res?.data?.[0]?.inactive_menus;
        this.userId = res?.data?.[0]?.id;
        if (!this.inactiveMenuIDs) throw Error(this.$translate('notifications-settings_categories-error-inactive-menu-ids'));
        if (!(Array.isArray(this.inactiveMenuIDs))) throw Error(this.$translate('notifications-settings_categories-error-not-array'));
        if (!(this.userId || this.userId >= 0)) throw Error(this.$translate('notifications-settings_categories-error-no-id'));
      } catch (e){
        this.error = e;
      }
      this.loading = false;
    },

    async changeNotificationStatus(id){
      if (!this.requestSent){
        if (this.notifications_status === 'disabled'){
          this.$store.dispatch('layout/alert', this.$translate('notifications-settings_notifications-disabled-alert'))
          return
        }
        this.requestSent = true;
        try {
          let inactive_menus = this.inactiveMenuIDs.includes(id) 
            ? this.inactiveMenuIDs.filter(menuId => !(menuId === id))
            : [ ...this.inactiveMenuIDs, id]
          let res = await baseApi.patch(`api/notification/config/${this.userId}/`, 
            { inactive_menus }
          );
          console.log(res?.data);
          this.inactiveMenuIDs = res?.data?.inactive_menus
          if (!this.inactiveMenuIDs) throw Error(this.$translate('notifications-settings_categories-error-inactive-menu-ids'));
          if (!(Array.isArray(this.inactiveMenuIDs))) throw Error(this.$translate('notifications-settings_categories-error-not-array'));
          if (!(this.userId || this.userId >= 0)) throw Error(this.$translate('notifications-settings_categories-error-no-id'));
          // change
        } catch(err){
          this.$store.dispatch(
            'layout/alert', 
            this.$translate('notifications-settings_request-error-title')+' \n'+err
          )
        }
        this.requestSent = false;
      }
    },

    backHandler(){
      this.$router.replace({
        name: 'NotificationsPage'
      })
    }
  },

  mounted(){
    this.getNotificationsStatus();
    backNavigateQueue.set(this.backHandler);
  }
}
</script>
<style>

</style>