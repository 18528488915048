<template>
  <router-link
    class="notifications__push-settings-link"
    :to="{
      name: 'NotificationsSettingsPage'
    }"
  >
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'push-settings-link__gear-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.25.833c.225.75.383 1.502 1.63 2.222 1.398.38 2.082.003 2.718-.42.665-.444.861-.314 1.127-.051l.346.345.12.12.175.174c.294.295.412.413 0 1.179l-.1.183c-.34.627-.658 1.273-.318 2.541.72 1.257 1.47 1.474 2.219 1.624.784.157.83.387.833.761v.906c0 .416 0 .583-.833.833l-.2.06c-.684.202-1.366.433-2.022 1.57-.38 1.398-.003 2.082.42 2.718.472.707.295.884 0 1.178l-.174.175-.24.24-.175.175c-.294.294-.412.412-1.178 0l-.183-.1c-.627-.34-1.273-.658-2.542-.318-1.256.72-1.473 1.47-1.623 2.219-.157.784-.387.83-.761.833h-.906c-.416 0-.583 0-.833-.833-.225-.751-.383-1.502-1.63-2.222-1.398-.38-2.082-.003-2.718.42-.665.444-.861.314-1.128.051l-.225-.225-.12-.12-.295-.295c-.294-.294-.412-.412 0-1.178l.15-.275c.32-.6.592-1.243.269-2.448-.72-1.258-1.47-1.475-2.22-1.625-.784-.157-.83-.388-.833-.762v-.905C0 9.167 0 9 .833 8.75c.751-.225 1.502-.383 2.222-1.63.38-1.398.003-2.082-.42-2.718-.472-.707-.295-.884 0-1.179l.238-.238.112-.113.239-.238c.294-.294.412-.412 1.178 0l.275.15c.6.32 1.242.591 2.447.269C8.383 2.333 8.6 1.583 8.75.833c.157-.784.388-.83.762-.833h.905c.416 0 .583 0 .833.833zM10 5.287a4.714 4.714 0 100 9.429 4.714 4.714 0 000-9.429z" fill="#4B5563"/></svg>
    <p class="small-medium">{{ $translate('notifications_settings-btn-title') }}</p>
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'push-settings-link__right-arrow'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".2" d="M1 11l5-5-5-5" stroke="#111114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
  </router-link>
</template>

<script>
export default {
  name: 'PushSettingsLink',

}
</script>

<style lang="scss">
.notifications__push-settings-link{
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-radius: 12px;
  overflow: hidden;
  padding: 14px 16px;
  margin: 0 0 16px;
  .push-settings-link__gear-icon{
    margin-right: 8px;
  }
  .push-settings-link__right-arrow{
    margin-left: auto;
    margin-right: 8px;
  }
}
</style>