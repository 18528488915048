<template>
  <div 
    class="notifications-control"
    @click="changeValue"
  >
    <p 
      class="medium mr-16"
    >{{ 
      $translate('notifications_push-switch_notifications') + 
      ' ' + 
      status === 'enabled' 
        ? $translate('notifications_push-switch_enabled') 
        : $translate('notifications_push-switch_disabled')
    }}</p>
    <div 
      class="notifications-control__toggler"
      :class="{
        'notifications-control__toggler--active': status === 'enabled',
      }"
      :style="status === 'enabled' ? {
        background: theme_color
      } : undefined"
    >
      <div class="notifications-control__toggler__circle-container">
        <div class="notifications-control__toggler__circle"></div>
      </div>
    </div>
  </div>
</template>
<script>

// TODO show loading state and use notifications/requestLoading state value
// disable toggler while requestLoading

import { mapState } from 'vuex';
export default {
  name: 'PushSwitch',
  data(){
    return {
      error: undefined,
    }
  },
  computed: {
    ...mapState('notifications', ['status']),
    ...mapState('layout', ['theme_color']),
  },
  methods: {
    async changeValue(){
      this.error = undefined
      try {
        await this.$store.dispatch('notifications/switchStatus');
        console.log('notifications status', this.status);
      } catch(err){
        console.log(err);
        this.error = err;
      }
    }
  }
}
</script>

<style lang="scss">
.notifications-control{
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  user-select: none;
  &__toggler{
    background: #EEF0F2;
    border-radius: 15px;
    // height: 20px;
    padding: 2px;
    width: 50px;
    // position: relative;
    
    &__circle-container{
      width: 100%;
      margin-left: auto;
      margin-right: 0;
      transition: width 0.1s ease-in;
    }
    &__circle{
      background: #FFFFFF;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
      // transition-property: left, right;
      // transition-duration: 3s, 5s;
      
    }
    &--active{
      .notifications-control__toggler__circle-container{
        width: 28px;
      }
    }
  }
  &__error-msg{
    flex-basis: 100%;
    color: #F5463B;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>